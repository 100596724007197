//*****************************************
//* 共通部(一覧・詳細)
//*****************************************/
#main{
  .moduletitle{
    width: 100%;
    display: table;
    border-top: 1px solid darken(@light-grey, 21.5%);
    margin-bottom: 25px;
    h3{
      padding-left: 15px;
      padding-left: 1.5rem;
      background:@light;
      display: table-cell;
      .font-size(1.8);
      height: 57px;
      height: 5.7rem;
      vertical-align: middle;
    }
  }
  #comment_main{
    ul{
      list-style: none;
      margin-bottom: 0;
      li{
        .font-size(1.2);
        margin: 0;
        text-align: right;
        color:@grey;
        img{
          display: none;
        }
        a{
          color:@grey;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
  .paginationbox{
    .totalpages{
      text-align: center;
      letter-spacing: .15em;
    }
    .pageNav{
      display: table;
      width: 100%;
    }
    .backbtn{
      display: table-cell;
      text-align: center;
      a{
        display: inline-block;
        width: 90%;
        padding:8px 10px;
        background: @inverse;
        border:1px solid @inverse;
        color:@white;
        .transition(@transition-fast ease-in-out);
        .transition-property(~"background,color");
        &:hover{
          background: transparent;
          color:@body-color;
        }
      }
    }
    .next,
    .prev{
      width: 11em;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      padding:8px;
      text-decoration: none;
      background: lighten(@inverse,51.5%);
      .transition(@transition-fast ease-in-out);
      .transition-property(~"background,color");
      color: @link-color-grey;
      &:hover{
        color:@white;
        background: @inverse;
      }
      &.disable{
        color:lighten(@link-color-grey,35%);
        background: lighten(@inverse,64.5%);
        &:hover{
          opacity:1;
          -ms-filter: alpha(opacity=100);
          filter: alpha(opacity=100);
        }
      }
    }
    .next:after{
      font-family: 'hybstemplate';
      content:'\e609';
      margin-left: 1em;
    }
    .prev:before{
      font-family: 'hybstemplate';
      content:'\e60f';
      margin-right: 1em;
    }
    .pages{
      display: table-cell;
      list-style: none;
      text-align: center;
      vertical-align: middle;
      li{
        margin: 0 3px;
        display: inline-block;
        a,
        span{
          display: block;
          min-width: 36px;
          padding:6px 9px;
          color: @link-color-grey;
        }
        a{
          .transition(opacity @transition-fast ease-in-out);
          background: lighten(@inverse,51.5%);
          text-decoration: none;
          &:hover{
            opacity:.8;
            -ms-filter: alpha(opacity=80);
            filter: alpha(opacity=80);
          }
        }
        span{
          color:@white;
          background: @inverse;
        }
      }
    }
  }
}

//*****************************************
//* 一覧設定
//*****************************************/
#main > .element_list{
  .list_imgflo{
    .thum_img img{
      .square(160px);
    }
  }
  .list_sort_links{ //カート用ソートボタン
    a{
      // text-decoration: none;
      // display: inline-block;
      // padding:.3em 1em;
      // background: @button-background;
      // margin: .5em 0;
      // .transition(background @transition-fast ease-in-out);
      // &:hover{
      //   background: @button-hover-background;
      // }
      .button;
      .button-xsmall;
      &:hover,&:focus{
        text-decoration: none;
      }
    }
  }
  .artloop{
    .transition(background @transition-fast ease-in-out);
    .detail_title + .entry{
      margin-top: 1em;
      color: @body-color;
    }
    h5 {
      .font-size(1.4);
      line-height: 1.4;
      margin-bottom: 0;
      .catetitle {
        display: inline-block;
        margin-left: 3px;
        .font-size(1);
        color: @grey;
        a {
          color: @grey;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &:hover {
      background: @light;
    }
  }
  // 画像パターン
  .list_imgflo{
    .clearfix;
    // 画像横並びパターン
    .list1,
    .list2{
      width: 25% !important;
      padding: 10px;
      margin: 0 0 29px 0;
      float: left;
      &.list1 h4 {
        //イメージのみの場合
        margin-bottom: 0;
      }
      img {
      }
      a.thum_img {
        margin: 0 auto;
        display: inline-block;
      }
      &.list2 a.thum_img {
        margin-bottom: 14px;
      }
      .catch {
        margin: 0.7em 0;
      }
    }
    // 2列パターン
    .list3{
      width: 50%;
      float: left;
      padding: 15px;
      margin: 0 0 29px 0;
      margin-bottom: 29px !important; //テンプレートにmargin-bottom:10px;が記述されているため
      img {
        .square(160px);
        &.small {
          .square(100px);
        }
      }
      >table{
        margin: 0;
      }
    }
    .wrapLink.block-2column:nth-child(2n+1) {
      clear:both;
    }
    // 1列パターン
    &.contents.img-list4{
      margin-left: 0;
      margin-right: 0;
    }
    .list4{
      border-top: 1px solid @light-grey;
      margin-bottom: 0 !important; //テンプレートにmargin-bottom:10px;が記述されているため
      padding: 14px 9px;
      h5{
        display: inline-block;
      }
      p {
        margin-top: 0;
      }
      .catch {
        margin: 0.7em 0;
      }
      >table{
        margin: 0;
      }
    }
  }
  .list_title1+.contents.img-list4{
    margin-top: -30px;
  }
  // 画像無しパターン
  .list_text{
    //1列
    .list1,
    .list2,
    .list3,
    .list4,
    &.list4 .artloop{
      border-top: 1px solid @light-grey;
      >td{
        width: 100%;
        padding:14px 9px;
        &.datetd{
          vertical-align: top;
          width: 1%;
          padding-right: 1em;
        }
        &.datetd p{
          margin: 0;
        }
      }
      >p {
        margin-top: 0;
        &:empty{
          margin-bottom: 0;
        }
      }
      .catch {
        margin: 1em 0 0;
        &:empty{
          margin: 0;
        }
      }
    }
    .list3{
      td p.date{
        margin: 0;
        padding-left: 1em;
      }
    }
    .list1,
    .list4,
    &.list4 .artloop{
      padding: 14px 9px;
      p {
        margin-top: 0;
      }
      h5+p{
        margin-top: .7em;
        &:empty{
          margin: 0;
        }
      }
      h5 +p:not(.catch){
        margin-bottom: 0;
      }
      .catch {
        margin: .7em 0 0;
      }
    }
    &.list1,
    &.list2 table,
    &.list3 table,
    &.list4{
      .wrapLink:first-child .artloop{
        border-top: none;
      }
    }
    &.list2>table,
    &.list3>table{
      border: 0;
      margin: 0;
      th,td{
        border: 0;
      }
    }
  }
  .list_title1 +.list_text,
  .list_title1 +.list1{
    margin-top: -29px;
  }
  .list_title1 +.list_imgflo{
    margin-top: -14px;
  }
}//.element_list

//*****************************************
//* 詳細設定
//*****************************************/
// 詳細部共通
#main > .element_detail{
  .font-size(1.4);
  //カテゴリ表記
  h4.catetitle{
    text-indent: 9px;
    text-indent: .9rem;
    line-height: 2.11;
    border: 1px solid darken(@light-grey, 4.5%);
    border-width: 1px 0;
    background-color: @light;
  }
  .detail_title{
    margin-bottom: 30px;
  }
  .hs_entrytitle{
    .font-size(2);
    line-height: 1.4;
    border-bottom: 1px solid darken(@light-grey, 21.5%);
    padding-bottom: .35em;
    margin-bottom: .3em;
  }
  .catchcp{
    margin-top: 0;
  }
  .entry{
    .clearfix;
    width: 100%;
    overflow: hidden;
  }
  .imgbox{
    float: none;
    padding: 0;
    width: auto;
    margin: 0 auto 30px; 
    >div>table{
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }
    td{
      padding: 0;
      >a>img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 600px;
        margin: 0 auto;
      }
    }
    .captipntext{
      max-width: 264px;
      .font-size(1);
      &:empty{display: none;}
    }
  }
  .detailfooter{
    position: relative;
    .edit_btn{
      .button;
      .button-inverse;
    }
    .socialButton +.edit_btn{
      position: absolute;
      top: 20px;
    }
    .list_link a,
    .paginationbox a,
    .pagetop a,
    .sitemaplink a{
      text-decoration: none;
    }
  }
}//.element_detail

//blog用スタイル読み込み
@import './_blog';
//form用スタイル読み込み
@import './_form';
//Cart用スタイル読み込み
@import './_cart';
//Sitemap用スタイル読み込み
@import './_sitemap';
//Privacypolicy用スタイル読み込み
@import './_privacy';
//Profile用スタイル読み込み
@import './_profile';
//Search用スタイル読み込み
@import './_search';