/*
 * Definitions
 */

// Table
@table-margin: @paragraph-margin;
@table-background: none;
@table-border: 1px solid @light-grey;
@table-border-radius: @border-radius;

// Table Caption
@table-caption-margin: 1rem 0;
@table-caption-font-family: @regular-font-family;
@table-caption-font-weight: @regular-font-weight;
@table-caption-font-size: @font-size-medium;
@table-caption-color: @body-color;
@table-caption-text-align: left;

// Table Head
@table-head-padding: 1rem;
@table-head-font-family: @medium-font-family;
@table-head-font-weight: @medium-font-weight;
@table-head-font-size: @font-size-small;
@table-head-text-align: left;
@table-head-text-transform: none;
@table-head-color: @body-color;
@table-head-border: @table-border;
@table-head-background: @light;

// Table Cell
@table-cell-padding: 1rem;
@table-cell-font-family: @regular-font-family;
@table-cell-font-weight: @regular-font-weight;
@table-cell-font-size: @font-size-small;
@table-cell-color: @body-color;
@table-cell-line-height: @line-height-medium;
@table-cell-vertical-align: middle;
@table-cell-border: @table-border;

// Table Reponsive
@table-responsive-breakpoint: @xsmall;


/*
 * Table
 */

table,
.table {
	margin: @table-margin;
	width: 100%;
	background: @table-background;
	border: @table-border;
	border-radius: @table-border-radius;
  &[border="0"]{
    border-width: 0;
    th,td{
      border-width: 0;
    }
  }
	caption {
		margin: @table-caption-margin;
		font-family: @table-caption-font-family;
		font-weight: @table-caption-font-weight;
		.font-size(@table-caption-font-size);
		color: @table-caption-color;
		text-align: @table-caption-text-align;
	}

	th {
		padding: @table-head-padding;
		font-family: @table-head-font-family;
		font-weight: @table-head-font-weight;
		.font-size(@table-head-font-size);
		color: @table-head-color;
		text-align: @table-head-text-align;
		text-transform: @table-head-text-transform;
		background: @table-head-background;
		border: @table-head-border;
	}

	td {
		padding: @table-cell-padding;
		font-family: @table-cell-font-family;
		font-weight: @table-cell-font-weight;
		.font-size(@table-cell-font-size);
		color: @table-cell-color;
		line-height: @table-cell-line-height;
		vertical-align: @table-cell-vertical-align;
		border: @table-cell-border;
	}
}

// Table Responsive
.table-responsive {
	margin: @table-margin;
	overflow-x: auto;
	min-height: 0.01%;

	@media @table-responsive-breakpoint {
		width: 100%;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: @table-border;

		> table {
			margin: 0;
			border: none;

			// Ensure the content doesn't wrap
			th,
			td {
				white-space: nowrap;

				&:first-child {
					border-left: none;
				}

				&:last-child {
					border-right: none;
				}
			}

			tr:first-child {

				> th,
				> td {
					border-top: none;
				}
			}

			tr:last-child {

				> th,
				> td {
					border-bottom: none;
				}
			}
		}
	}
}