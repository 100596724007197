@font-face {
  font-family: 'hybstemplate';
  src:url('@{font-path}/hybstemplate/hybstemplate.eot?iemrx0');
  src:url('@{font-path}/hybstemplate/hybstemplate.eot?#iefixiemrx0') format('embedded-opentype'),
    url('@{font-path}/hybstemplate/hybstemplate.woff?iemrx0') format('woff'),
    url('@{font-path}/hybstemplate/hybstemplate.ttf?iemrx0') format('truetype'),
    url('@{font-path}/hybstemplate/hybstemplate.svg?iemrx0#hybstemplate') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'hybstemplate';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-addCart:before {
  content: "\e60c";
}

.icon-arrow-b:before {
  content: "\e60d";
}

.icon-arrow-t:before {
  content: "\e60e";
}

.icon-double-arrow:before {
  content: "\e60b";
}

.icon-NEW:before {
  content: "\e60a";
}

.icon-arrow-l:before {
  content: "\e60f";
}

.icon-arrow-r:before {
  content: "\e609";
}

.icon-hb:before {
  content: "\e607";
}

.icon-fb:before {
  content: "\e605";
}

.icon-gp:before {
  content: "\e606";
}

.icon-tw:before {
  content: "\e608";
}

.icon-cart:before {
  content: "\e600";
}

.icon-mailicon:before {
  content: "\e601";
}

.icon-pagetop:before {
  content: "\e602";
}

.icon-pencil:before {
  content: "\e603";
}

.icon-search:before {
  content: "\e604";
}

.icon-google-plus:before {
  content: "\ea88";
}

.icon-sp:before {
  content: "\e630";
}

