#main > .element.profile{
	.module_profile_table{
		tr{
			border-top: 1px solid @light-grey;
		}
		th{
			background-color: transparent;
			padding:10px;
			.font-size(1.4);
			line-height: 1.5;
			font-weight: normal;
			color:@black;
			text-align: right;
		}
		td{
			padding-left: 30px;
			color:lighten(@dark-grey, 15%);
		}
		address{
			font-style: normal;
		}
	}
}