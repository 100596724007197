/*
 * Definitions
 */

// Form Message
@form-message-margin: @label-margin;
@form-message-font-family: @paragraph-font-family;
@form-message-font-weight: @paragraph-font-weight;
@form-message-font-size: @font-size-small;
@form-message-color: @body-color;
@form-message-line-height: @paragraph-line-height;

// Form Message Indicators
@form-message-error-color: @error;
@form-message-warning-color: @warning;
@form-message-success-color: @success;
@form-message-info-color: @info;


/*
 * Form Message
 */

// Form Message
.form-message {
	margin: @form-message-margin;
	font-family: @form-message-font-family;
	font-weight: @form-message-font-weight;
	.font-size(@form-message-font-size);
	color: @form-message-color;
	line-height: @form-message-line-height;
	
	// Error
	&.error {
		color: @form-message-error-color;
	}

	// Warning
	&.warning {
		color: @form-message-warning-color;
	}
	
	// Success
	&.success {
		color: @form-message-success-color;
	}
	
	// Info
	&.info {
		color: @form-message-info-color;
	}
}