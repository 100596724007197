/*
 * Definitions
 */

// Button Inverse
@button-inverse-color: @light;
@button-inverse-background: @inverse;
@button-inverse-border: none;
@button-inverse-hover-color: @button-inverse-color;
@button-inverse-hover-background: darken(@button-inverse-background, 10%);
@button-inverse-hover-border: none;

// Button Primary
@button-primary-color: @white;
@button-primary-background: @primary;
@button-primary-border: none;
@button-primary-hover-color: @button-primary-color;
@button-primary-hover-background: darken(@primary, 10%);
@button-primary-hover-border: none;

// Button Secondary
@button-secondary-color: @white;
@button-secondary-background: @secondary;
@button-secondary-border: none;
@button-secondary-hover-color: @button-secondary-color;
@button-secondary-hover-background: darken(@secondary, 10%);
@button-secondary-hover-border: none;

// Button Tertiary
@button-tertiary-color: @white;
@button-tertiary-background: @tertiary;
@button-tertiary-border: none;
@button-tertiary-hover-color: @button-tertiary-color;
@button-tertiary-hover-background: darken(@tertiary, 10%);
@button-tertiary-hover-border: none;

// Button Border
@button-border-color: @dark;
@button-border-border: 1px solid @button-border-color;
@button-border-background: transparent;
@button-border-hover-color: @white;
@button-border-hover-border: 1px solid @button-border-color;
@button-border-hover-background: @button-border-color;

// Button Text
@button-text-color: @dark;
@button-text-hover-color: @primary;


/*
 * Button Styles
 */

// Button Inverse
.button-inverse {
	.button-theme(@button-inverse-color, @button-inverse-background, @button-inverse-border, @button-inverse-hover-color, @button-inverse-hover-background, @button-inverse-hover-border);
}

// Text Button
.button-text {
	.button-theme(@button-text-color, transparent, none, @button-text-hover-color, transparent, none);
}

// Button Primary
.button-primary {
	.button-theme(@button-primary-color, @button-primary-background, @button-primary-border, @button-primary-hover-color, @button-primary-hover-background, @button-primary-hover-border);
}

// Button Secondary
.button-secondary {
	.button-theme(@button-secondary-color, @button-secondary-background, @button-secondary-border, @button-secondary-hover-color, @button-secondary-hover-background, @button-secondary-hover-border);
}

// Button Tertiary
.button-tertiary {
	.button-theme(@button-tertiary-color, @button-tertiary-background, @button-tertiary-border, @button-tertiary-hover-color, @button-tertiary-hover-background, @button-tertiary-hover-border);
}


// Button Border
.button-border {
	.button-theme(@button-border-color, @button-border-background, @button-border-border, @button-border-hover-color, @button-border-hover-background, @button-border-hover-border);
}