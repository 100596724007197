/*
 * Definitions
 */

// Root Size
@root-size: 10px;
@root-size-xlarge: @root-size;
@root-size-large: @root-size;
@root-size-medium: @root-size;
@root-size-small: @root-size - 1;
@root-size-xsmall: @root-size - 1;


/*
 * Root Size
 */

:root {
	font-size: @root-size;

	@media @xlarge {
		font-size: @root-size-xlarge;
	}
	
	@media @large {
		font-size: @root-size-large;
	}

	@media @medium {
		font-size: @root-size-medium;
	}
	
	@media @small {
		font-size: @root-size-small;
	}

	@media @xsmall {
		font-size: @root-size-xsmall;
	}
}