@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-thin.eot');
    src: url('@{font-path}/Roboto/roboto-thin.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-thin.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-thin.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-thin.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-thin.svg#robotothin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-light.eot');
    src: url('@{font-path}/Roboto/roboto-light.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-light.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-light.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-light.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-light.svg#robotolight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-regular.eot');
    src: url('@{font-path}/Roboto/roboto-regular.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-regular.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-regular.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-regular.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-regular.svg#robotoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-medium.eot');
    src: url('@{font-path}/Roboto/roboto-medium.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-medium.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-medium.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-medium.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-medium.svg#robotomedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-bold.eot');
    src: url('@{font-path}/Roboto/roboto-bold.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-bold.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-bold.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-bold.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-bold.svg#robotobold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-black.eot');
    src: url('@{font-path}/Roboto/roboto-black.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-black.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-black.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-black.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-black.svg#robotoblack') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-thinitalic.eot');
    src: url('@{font-path}/Roboto/roboto-thinitalic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-thinitalic.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-thinitalic.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-thinitalic.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-thinitalic.svg#robotothin_italic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-lightitalic.eot');
    src: url('@{font-path}/Roboto/roboto-lightitalic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-lightitalic.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-lightitalic.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-lightitalic.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-lightitalic.svg#robotolight_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-italic.eot');
    src: url('@{font-path}/Roboto/roboto-italic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-italic.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-italic.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-italic.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-italic.svg#robotoitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-mediumitalic.eot');
    src: url('@{font-path}/Roboto/roboto-mediumitalic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-mediumitalic.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-mediumitalic.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-mediumitalic.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-mediumitalic.svg#robotomedium_italic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-bolditalic.eot');
    src: url('@{font-path}/Roboto/roboto-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-bolditalic.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-bolditalic.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-bolditalic.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-bolditalic.svg#robotobold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('@{font-path}/Roboto/roboto-blackitalic.eot');
    src: url('@{font-path}/Roboto/roboto-blackitalic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/Roboto/roboto-blackitalic.woff2') format('woff2'),
         url('@{font-path}/Roboto/roboto-blackitalic.woff') format('woff'),
         url('@{font-path}/Roboto/roboto-blackitalic.ttf') format('truetype'),
         url('@{font-path}/Roboto/roboto-blackitalic.svg#robotoblack_italic') format('svg');
    font-weight: 900;
    font-style: italic;
}