//一覧・フォームページ共通
.formbox{
  textarea{max-width: 100%;}
}
.element_mailform{
  .contents > .formflow{
    margin-bottom: 45px;
    text-align: center;
    li{
      display: inline-block;
      margin: 0 25px;
      width: 23%;
      background: @light;
      position: relative;
      line-height:2.5;
      .font-size(1.4);
      &.flow1:before,
      &.flow2:before,
      &.flow3:before{
        display: inline-block;
        font-family: 'Roboto';
        .font-size(2.2);
        line-height: 1;
        vertical-align: middle;
        margin-right: .3em;
        text-indent: -.5em;
      }
      &.flow1:before{
        content: '01';
      }
      &.flow2:before{
        content: '02';
      }
      &.flow3:before{
        content: '03';
      }
      &.flow1:after,
      &.flow2:after{
        line-height: 2;
        position: absolute;
        right:-37px;
        display: inline-block;
        font-family: 'hybstemplate';
        content: "\e60b";
        color:@body-color;
        .font-size(1.7);
      }
      &.current{
        background: @red;
        color:@white;
      }
    }
  }
}

//フォーム一覧ページバナー部
.entry_formbanner{
  table > tbody > tr > td{
    padding: 0;
    vertical-align: top;
  }
  table table{
    width: 100%;
    border-width: 0;
    margin-top: 0;
    margin-bottom: 0;
    > tbody > tr > td{
      padding: 0 10px 10px;
      border-width: 0;
    }
  }
  >table{
    border-width: 0;
    margin-top: 0;margin-bottom: 0;
    >tbody>tr{
      >th,>td{
        border-width: 0;
      }
    }
  }
  .element_blog_banner{
    display: table;
    a{
      display: table-cell;
      vertical-align: middle;
      &:extend(#side > .block .element_form_banner a);
      line-height: 1.5;
      padding: 1em .2em;
      &:hover {
        opacity: .8;
        -ms-filter: alpha(opacity=80);
        filter: alpha(opacity=80);
      }
    }
    width: 100%;
  }
}

//フォーム詳細
.element_mailform > .contents > form{
  > div > table{
    > tbody > tr{
      >td{
        vertical-align: middle;
        padding: 10px 0;
      }
      >td[colspan="2"]{ //キャプション
        padding: 15px 0 5px;
      }
      hr{
        margin: 0 0 1em;
      }
      td.form_left{   //項目セル
        width: 1%;
        text-align: right;
        padding-right: 2em;
      }
      td.form_right{  //入力セル
        label{
          .font-size(1.4);
        }
        span{
          margin-right: 1.5em;
          display: inline-block;
          margin-top: .5em;
          margin-bottom: .5em;
        }
      }
    }
  }
  .needs{
    color:@error;
    margin-left: .5em;
  }
  input[type="text"]{
    &+br{
      display: none;
    }
  }
  input[type="email"]{
    &+br{
      display: none;
    }
  }
  input[type="checkbox"],
  input[type="radio"]{
    margin-right: .4em;
    line-height: 1.4;
  }
  input[type="file"]{
    margin-top: .7em;
    margin-bottom: .7em;
  }
  textarea{
    resize: vertical;
    &+br{
      display: none;
    }
  }
  input[name="GetAdrsZip"]{
    width: 10em;
    margin: 0 .5em;
    display: inline-block;
  }
  input[name="zip_button"]{
    .font-size(1.2);
    line-height: 2.95;
    border:none;
    border-radius:2px;
    color:@white;
    background: lighten(@inverse,24%);
    display: inline-block;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: bottom;
    &:hover{
      opacity:.8;
      -ms-filter: alpha(opacity=80);
      filter: alpha(opacity=80);
    }
  }
  select{
    margin-top: .7em;
    margin-bottom: .7em;
    width: auto;
    display: inline-block;
    &[multiple]{
      padding:0 .5em;
      display: block;
    }
  }
  input.submit{
    .font-size(2.1);
    width: 50%;
    border:none;
    color:@white;
    font-weight: normal;
    background: @orange;
    margin-bottom: 30px;
    line-height:1.3;
    height:auto;
    border-radius: 5px;
    padding: 12px 15px;
    &:hover{
      opacity:.8;
      -ms-filter: alpha(opacity=80);
      filter: alpha(opacity=80);
    }
  }
}
#ExplainSSL{
  background: @white;
  margin-bottom: 50px;
  height: 3.1em;
  overflow: hidden;
  font-size:12px !important;
  font-size:1.2rem !important;
  color:lighten(@dark-grey,15%) !important;
  line-height: 1.8;
  position: relative;
  .transition(@transition-medium ease-in-out);
  cursor:pointer;
  &:after{
    content:'▼';
    display: block;
    position: absolute;
    line-height: 1px;
    .font-size(1.5);
    right:30px;
    top:20px;
  }
  &.open{
    height: 20em;
    &:after{
      content:'▲';
    }
  }
  td {
    color:lighten(@dark-grey,15%);
    padding:0;
    .font-size(1.2);
  }
}
.formborder{
  &>table{
    border-width: 0;
    margin-top: 0;
    margin-bottom: 0;
    >tbody>tr>th,
    >tbody>tr>td{
      border-width: 0;
    }
  }
}