#gmenu{
	ul li a:after{
		content: '';
		display:block;
		width: 0;
		position:relative;
		top:12px;
		border-bottom:2px solid #070707;
		margin: 0 auto;
		.transition(width @transition-medium ease-in-out);
	}
	ul li a:hover:after,
	ul li a:focus:after,
	ul li a.current:after{
		width: 50%;
	}
}
