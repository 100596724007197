.post_detail{
  margin-bottom: 30px;
  .clearfix;
  >.date,
  >.category,
  >.commentCount{
    margin: 0;
    display: inline-block;
    color:@grey;
    line-height: 1.5;
  }
  >.date{
    margin-right: 1em;
  }
  >.category{
    margin-right: 1em;
    padding: 0 .7em;
    border: 1px solid lighten(@grey,10%);
    border-width: 0 1px;
  }
  >.commentCount{
    float: right;
    .count{
      margin-left: .2em;
      font-family:'Roboto';
      color:@white;
      white-space: nowrap;
      display: inline-block;
      position: relative;
      background: @grey;
      border-radius: 3px;
      padding: 0 .7em;
      line-height: 1.5;
      &:after{
        .arrow();
        border-top: .4em solid @grey;
        border-right: .4em solid transparent;
        top:100%;
        left:40%;
      }
    }
  }
}
//コメントリスト
.comment_list{
  >strong{
    .font-size(1.4);
    color:darken(@grey,6%);
    margin-bottom: .5em;
    display: block;
  }
  >.comments{
    margin-top: .5em;
    list-style: none;
    .comment{
      margin: 0 0 1em;
      p{
        display: inline-block;
        .font-size(1.2);
      }
    }
    .commentHead{
      background: @light;
      padding:0 22px;
      p{
        color:@grey;
        margin:.8em 0;
        a{
          color:@link-color-blue;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .commentBody{
      border:10px solid @light;
      border-width: 0 10px 10px;
      >p{
        margin: 0;
        padding:12px;
      }
    }
  }
}
#commentForm{
  .commentFormTitle{
    margin: 0;
    display: inline-block;
    cursor: pointer;
    position: relative;
    width: 100%;
    text-align: center;
    line-height:2.5;
    border:1px solid darken(@light,12%);
    &:after{
      right:20%;
      position: absolute;
      display: inline-block;
      content: '▼';
    }
    &:hover,
    &.open{
      background: darken(@light,1%);
    }
  }
  #formComment{
    display:none;
    .clearfix;
    padding:30px 45px;
    border:1px solid darken(@light,12%);
    border-width: 0 1px 1px;
    table{
      width: 100%;
      margin-bottom: 30px;
      th{
        width: 1%;
        vertical-align: top;
        white-space: nowrap;
        font-weight: normal;
        .font-size(1.4);
        padding-top: 1.1em;
        padding-right: 15px;
        background: none;
        .text-error{
          margin-left: .5em;
        }
      }
      td{
        padding: 5px 0;
        .text-error{
          display: block;
          .font-size(1.2);
        }
        >input,
        >textarea{
          margin: 0;
        }
      }
    }
    .commentBody{
      height: 9em;
    }
    .captcha>td{
      padding-top: 15px;
      p{
        .font-size(1.2);
        margin: 0 0 2px;
        float: left;
      }
      .commentCaptcha{
        width: 55%;
        float: left;
      }
      .text-error{
        float: left;
      }
    }
    .commentCaptchaImg{
      float: right;
    }
    input[type="submit"]{
      .font-size(2.1);
      width: 50%;
      border:none;
      color:@white;
      font-weight: normal;
      background: @orange;
      margin-bottom: 30px;
      line-height:1.3;
      height:auto;
      border-radius: 5px;
      &:hover{
        opacity:.8;
        -ms-filter: alpha(opacity=80);
        filter: alpha(opacity=80);
      }
    }
    .comment_notice{
      .font-size(1.2);
      line-height: 1.8;
      margin: 0;
    }
  }
}