#main > .element_list_seach{
	.search_result{
		strong{
			margin: 0 .5em;
		}
	}
	.paginationbox td{
		.font-size(1.2);
	}
	ul{
		list-style: none;
	}
	li{
		margin-bottom: 30px;
	}
}