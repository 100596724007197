#main > .element.sitemap{
	.cat,
	.art{
		list-style: none;
	}
	.cat{
		margin-bottom: 50px;
		&>li{
			margin-left: 0;
			h4.catetitle{
				margin-bottom: 15px;
				display: table;
				width: 100%;
				a{
					display: table-cell;
					height:50px;
					height:5rem;
					text-indent: 0;
					background-color: transparent;
					border-width: 1px 0 0 0;
					.font-size(1.8);
					font-weight: bold;
					vertical-align: middle;
				}
				a:hover{
					background-color: @light;
					color:@link-color;
				}
			}
		}
	}
	.art{
		.contents{
			.clearfix;
			width: 100%;
			h5{
				width: 25%;
				float: left;
				display:table-row;
				&:before {
					width: 1%;
					display: table-cell;
					font-family: 'hybstemplate';
					content: "\e609";
					padding-right: 10px;
					text-decoration: none;
				}
				a{
					display: table-cell;
					text-align: left;
					.font-size(1.6);
					line-height: 1.5;
					font-weight: bold;
				}
				&:after{
					display: table-cell;
					content:'';
					border:1px solid darken(@light-grey, 21.5%);
					border-width: 1px 0 0;
					position: relative;
					top:.7em;
				}
			}
			p{
				padding-left: 95px;
				position: relative;
				margin-top: 0;
				margin-bottom: .5em;
				margin-left: 25%;
				&:before{
					content:'';
					display: block;
					position: absolute;
					.size(40px,1.9em);
					border:1px solid darken(@light-grey, 21.5%);
					border-width: 0 0 1px 1px;
					top:-1.25em;
					left:40px;
				}
			}
			h5+p:before{
				height:0px;
				width: 80px;
				left:0px;
				top:.7em;
				border-width:0 0 1px 1px;
			}
			p:first-child,
			p:first-child ~p{
				margin-left: -40px;
			}
		}
	}
}