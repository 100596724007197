
@font-face {
    font-family: 'NotoSans';
    src: url('@{font-path}/NotoSans/notosans-regular.eot');
    src: url('@{font-path}/NotoSans/notosans-regular.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/NotoSans/notosans-regular.woff2') format('woff2'),
         url('@{font-path}/NotoSans/notosans-regular.woff') format('woff'),
         url('@{font-path}/NotoSans/notosans-regular.ttf') format('truetype'),
         url('@{font-path}/NotoSans/notosans-regular.svg#noto_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('@{font-path}/NotoSans/notosans-italic.eot');
    src: url('@{font-path}/NotoSans/notosans-italic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/NotoSans/notosans-italic.woff2') format('woff2'),
         url('@{font-path}/NotoSans/notosans-italic.woff') format('woff'),
         url('@{font-path}/NotoSans/notosans-italic.ttf') format('truetype'),
         url('@{font-path}/NotoSans/notosans-italic.svg#noto_sansitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'NotoSans';
    src: url('@{font-path}/NotoSans/notosans-bold.eot');
    src: url('@{font-path}/NotoSans/notosans-bold.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/NotoSans/notosans-bold.woff2') format('woff2'),
         url('@{font-path}/NotoSans/notosans-bold.woff') format('woff'),
         url('@{font-path}/NotoSans/notosans-bold.ttf') format('truetype'),
         url('@{font-path}/NotoSans/notosans-bold.svg#noto_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'NotoSans';
    src: url('@{font-path}/NotoSans/notosans-bolditalic.eot');
    src: url('@{font-path}/NotoSans/notosans-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('@{font-path}/NotoSans/notosans-bolditalic.woff2') format('woff2'),
         url('@{font-path}/NotoSans/notosans-bolditalic.woff') format('woff'),
         url('@{font-path}/NotoSans/notosans-bolditalic.ttf') format('truetype'),
         url('@{font-path}/NotoSans/notosans-bolditalic.svg#noto_sansbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}